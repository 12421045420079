// @flow
import React, {Fragment} from 'react';
import Header from '../Header';
import Footer from '../Footer';
import styles from './About.module.scss';

const About = () => {
  return (
    <Fragment>
      <Header/>
      <section className='hero'>
        <div className={ `hero-body ${styles['about__hero']}` }>
          <div className='container'>
            <h1 className={ `title has-text-centered ${styles['about__hero-title']}` }>
              About
            </h1>
            <h2 className={ `subtitle has-text-centered ${styles['about__hero-subtitle']}` }>
              With Guriosity, I want to encourage tech teams to share their learnings and make sure they are read by you.
            </h2>
          </div>
        </div>
      </section>
      <div>
        <div className={ `${styles['about__container__team']}` }>
          <div className={ `${styles['about__container__team-text']}` }>
            <div className={ `${styles['about__container__image']}` }>
              <img className={ `${styles['about__container__team-image']}` } src="/media/photo.png" alt="Ari" />
            </div>
            <br/>
            <p>
              Hello! I am Ari. I've worked for 3 startups in Paris as a Data Engineer. The first didn’t have an engineering blog. The second had one, but I never took the courage to write. The third didn’t consider it a priority.
            </p><br/><p>
              Writing technical articles is hard. As a developer, we’re often behind schedule and we’ve little time to transform ourselves into writers. If publishing an article isn’t a priority for your team, it won't be done.
            </p><br/><p>
              Still, French companies have engineering blogs. And some very good ones!
            </p>
            <br/>
          </div>
        </div>
        <div className={ `${styles['about__container__problem']}` }>
          <div className={ `${styles['about__container__problem-text']}` }>
            <h2 className={ `${styles['about__container__problem-subtitle']}` }>The problem</h2>
            <p>
              French engineering blogs were scattered all over the place and mixed technical and promotional content.
            </p>
          </div>
          <br/>
        </div>
        <div className={ `${styles['about__container__solution']}` }>
          <div className={ `${styles['about__container__solution-text']}` }>
            <h2 className={ `${styles['about__container__solution-subtitle']}` }>Enter Guriosity</h2>
            <p>
              I went through all the <a href='https://guriosity.com'>French engineering blogs</a> I found and organized them in one site. Here, you’ll only find the best articles written by French tech teams.
            </p><br/><p>
              I believe that all tech teams have tremendous value to share. With Guriosity, I want to encourage tech teams to share their learnings and make sure they are read by you. Don't miss out on the best articles!              
            <p><br/></p>
              By subscribing, you’ll learn about new technologies, ways of working, stay updated on the French tech market and boost your career.
            </p>
          </div>
        </div>
      </div>
      <Footer/>
    </Fragment>
  );
}

export default About;
