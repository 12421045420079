// @flow
import React from 'react';
import About from '../components/About';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const AboutTemplate = ({data}) => {

	return (
		<Layout customSeo>
			<SEO title='About' description='I believe that all tech teams have tremendous value to share. With Guriosity, I want to encourage tech teams to share and make sure they are read by you.'/>
			<About data={data}/>
		</Layout>
	)
};

export default AboutTemplate;
